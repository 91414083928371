'use strict';

angular.module('enervexSalesappApp').factory('CommandManager', function CommandManager(localStorageService) {
	return {
		_cache:{},
		cacheCommands: true,
		islocalStorageSupported:function(forceCache) {
			var result = null
			if (forceCache) {
				result = false
			} else if(localStorageService.isSupported) {
				result = true
			} else {
				result = false
			}
			// console.log("islocalStorageSupported:"+result)
			return result
		},
		setStorage:function(name, value, forceCache) {
			if(this.islocalStorageSupported(forceCache)) {
				localStorageService.set(name, value)
			} else {
				//use in memory
				this._cache[name] = value
			}
		},
		removeStorage:function(name, forceCache) {
			if(this.islocalStorageSupported(forceCache)) {
				localStorageService.remove(name)
			} else {
				//use in memory
				this._cache[name] = null
			}
		},
		getStorage: function(name, forceCache) {
			if(this.islocalStorageSupported(forceCache)) {
				return localStorageService.get(name)
			} else {
				//use in memory
				return this._cache[name]
			}
		},
		getCommands: function(design) {
			var json = this.getStorage("commands-"+design._id, this.cacheCommands)
			if (json) {
				var parsed = JSON.parse(json)
				return parsed ||[]
			} else {
				return []
			}
		},
		createCommand: function(design, command, cb) {
			var self = this
			// console.log("creating command")
			setTimeout(function() {
				try {
					var start = Date.now()
					var now = new Date()
					command.id = design._id + "-" + now.getTime()
					command.timeString = now.toISOString()
					var commands = self.getCommands(design)
					self.printCommands("before createCommand", commands)
					commands.push(command)
					var commandKey = "commands-"+design._id
					self.setStorage(commandKey, angular.toJson(commands), self.cacheCommands)
					var snapshotKey = "snapshot-"+command.id
					self.setStorage(snapshotKey, angular.toJson(design), self.cacheCommands)
					var stat = Date.now() - start
					self.printCommands("after createCommand "+stat, commands)
					if (cb){
						cb()
					}
				} catch(e) {
					console.log("error creatting command",e)
				}
			}, 0)
		},
		restoreCommand: function(commandId, design) {
			var self = this
			var commands = this.getCommands(design)
			this.printCommands("before restoreCommand", commands)
			var keep = []
			var result = null
			_.each(commands, function(command){
				if (result) {
					self.removeStorage("snapshot-" + command.id, self.cacheCommands)
				} else if (command.id == commandId) {
					keep.push(command)
					var json = self.getStorage("snapshot-"+command.id, self.cacheCommands)
					result = JSON.parse(json)
					if (!result) {
						throw new Error("failure loading undo")
					}
					// console.log("restored "+command.timeString, result.fc5layout.Layout.length)
				} else {
					// console.log("keeping "+command.timeString)
					keep.push(command)
				}
			})
			if (result) {
				self.setStorage("commands-"+design._id, angular.toJson(keep), self.cacheCommands)
				this.printCommands("after restoreCommand", keep)
				return result
			}
		},
		printCommands: function(title, commands){
			// console.log("commands "+title)
			// _.each(commands, function(command){
			// 	console.log("  "+ command.timeString + " " + command.name)
			// })
		},
		clearTransactions: function(design) {
			var self = this
			var commands = this.getCommands(design)
			//remove all snapshots and commands
			var keys = localStorageService.keys()
			_.each(keys, function(key){
				if (key.indexOf("snapshot-") == 0 || key.indexOf("commands-") == 0) {
					self.removeStorage(key, false)
				}
			})
			this.printCommands("before clearTransactions", commands)
			self.setStorage("commands-"+design._id, angular.toJson([]), self.cacheCommands)
			_.each(commands, function(command){
				self.removeStorage("snapshot-" + command.id, self.cacheCommands)
			})
			commands = this.getCommands(design)
			this.printCommands("after clearTransactions", commands)
		},
		flushCache: function() {
			if (localStorageService.isSupported) {
				// console.log("clearing "+localStorageService.length())
				localStorageService.clearAll();
				// console.log("new length "+localStorageService.length())
			}
		}
	}
})
